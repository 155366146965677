
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import SettlementOfferTable from "@/components/reusable/claims/SettlementOfferTable.vue";
import SettlementOfferForm from "@/components/reusable/claims/SettlementOfferForm.vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "add-motor-insurance-claims-settlement-offer",
  components: {
    SettlementOfferTable,
    SettlementOfferForm,
  },
  setup() {
    const { findObjectByKeyValue, generateUUID } = ResusableFunctions();
    const router = useRouter();
    const publicId = router.currentRoute.value.params.publicId;
    const claim = ref({} as any);

    // Get the current user from the store
    const user = computed(() => store.getters.currentUser);

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    // Fetch claim information
    const getClaimInformation = async () => {
      // Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        try {
          const { data } = await ApiService.get(
            `${variables.UNDERWRITERS_CLIENT_CLAIMS_ROUTE}/${publicId}`
          );

          // Assign data to claim
          claim.value = data.data;

          if (data.data.isSettlementOfferAccepted) {
            variables.toastAlert(
              "Claim settlement offer has already been accepted",
              "error"
            );
            router.push({
              name: "underwriter-client-claims-listing",
            });
          }
        } catch (error: any) {
          variables.toastAlert(error?.response?.data?.error, "error");
          router.go(-1);
        }
      }
    };

    const keysToCheck = [
      "vehicleDamageAmount",
      "propertyDamageAmount",
      "bodilyInjuryMedicalBill",
      "deathLossClaim",
    ];

    // Filtered third-party data based on defined keys with truthy values
    const filteredThirdParty = computed(() => {
      const result: {
        key: string;
        value: any;
        index: number;
        name: string;
      }[] = [];

      // For each third-party claim
      claim.value.metadata?.thirdParty?.forEach((item) => {
        keysToCheck.forEach((key, idx) => {
          let name = "";

          const record = findObjectByKeyValue(
            claim.value.metadata?.thirdParty,
            key,
            item[key]
          );

          if (key === "vehicleDamageAmount") {
            name = `${record?.vehicleDamageVehicleBrand} ${record?.vehicleDamageVehicleModel} (${record?.vehicleDamageRegistrationNumber})`;
          }

          if (key === "propertyDamageAmount") {
            name = record?.propertyDamageFullName;
          }

          if (key === "bodilyInjuryMedicalBill") {
            name = record?.bodilyInjuryFullName;
          }

          if (key === "bodilyInjuryMedicalBill") {
            name = record?.bodilyInjuryFullName;
          }

          if (key === "deathLossClaim") {
            name = record?.deathFullName;
          }

          // If the key has a truthy value
          if (item[key]) {
            const index = result.filter((r) => r.key === key).length + 1; // count the current occurrences of this key
            result.push({
              key,
              value: item[key],
              index,
              name,
            });
          }
        });
      });

      return result;
    });

    const formatKeyName = (key, index, name = "") => {
      // Convert camelCase or snake_case to a readable format
      const formattedKey = key
        .replace(/([A-Z])/g, " $1") // Adds space before capital letters
        .replace(/^./, (str) => str.toUpperCase()) // Capitalizes the first letter
        .replace(/([0-9])([A-Za-z])/g, "$1 $2"); // Adds space between number and letters

      if (
        name !== "" &&
        name !== null &&
        name !== undefined &&
        typeof name === "string" &&
        !name.includes("null") &&
        !name.includes("undefined")
      ) {
        return `${formattedKey} for ${name}`;
      }

      return `${formattedKey} ${index}`;
    };

    // Set breadcrumbs and page title on mounted
    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offer/Discharge Voucher", [
        "Claims",
        "Motor Insurance Claim",
      ]);
      getClaimInformation();
      document.title =
        "Motor Insurance Claim: Settlement Offer | " +
        process.env.VUE_APP_TITLE;
    });

    return {
      addCommaToNumberString,
      generateUUID,
      formatKeyName,
      claim,
      user,
      filteredThirdParty,
    };
  },
});
